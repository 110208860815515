/* Collection default theme */

.ReactVirtualized__Collection {
  outline: none;
}

.ReactVirtualized__Collection__innerScrollContainer {
  outline: none;
}

/* Grid default theme */

.ReactVirtualized__Grid {
  outline: none;
}

.ReactVirtualized__Grid__innerScrollContainer {
  height: auto;
  outline: none;
}

/* Table default theme */

.table-wrapper {
  flex: 1;
}

.table-wrapper.with-hover .ReactVirtualized__Table__row:hover {
  cursor: pointer;
  background-color: #f5f4f2;
}

.ReactVirtualized__Table__Grid {
  /* table body */
  outline: none;
}

.ReactVirtualized__Table__headerRow {
  /* table headers */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;

  background-color: #e8e2e1;
  border-radius: 5px 5px 0 0;
  color: #8b1052;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 7px;
  border: 1px solid #e8e2e1;
  border-top: none;
  outline: none;
  background-color: #fcfcfb;
  transition: background-color 0.1s ease-in-out;
  color: #262626;
}

.ReactVirtualized__Table__row:hover {
  color: #262626;
}

.ReactVirtualized__Table__row.selected {
  background-color: #f9f8cc;
}

.ReactVirtualized__Table__row:last-child {
  border-radius: 0 0 5px 5px;
}

.ReactVirtualized__Table__row.editor-row:hover {
  background-color: transparent !important;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn {
  /* make header title unselectable */
  user-select: none;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 7px;
  min-width: 0px;
  outline: none;
  padding: 7px;
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__rowColumn p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  height: 20px;
  width: 20px;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
  outline: none;
}
